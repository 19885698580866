import React, { useState, useRef } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import axios from "axios";

import "./FeaturesSplit.css";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner padding-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setImagePreview(URL.createObjectURL(selectedFile));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
    setImagePreview(URL.createObjectURL(droppedFile));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleUpload = async () => {
    if (!file) return;

    setLoading(true);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://t5g6abszk1.execute-api.us-east-2.amazonaws.com/test/detect-url?upload_class=TEST",
      headers: {
        "Content-Type": "image/jpeg",
      },
      data: file,
    };

    try {
      const response = await axios.request(config);

      setResults(response.data.body.inference.labels);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // This will now execute after the API call is complete
    }
  };

  const startCamera = async () => {
    setShowCamera(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
    } catch (err) {
      console.error("Error accessing the camera", err);
    }
  };

  const capturePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob) => {
      setFile(blob);
      setImagePreview(URL.createObjectURL(blob));
      setShowCamera(false);
      video.srcObject.getTracks().forEach((track) => track.stop());
    }, "image/jpeg");
  };

  const FileUploadArea = () => (
    <div
      className="file-upload-area"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <div className="upload-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="17 8 12 3 7 8"></polyline>
          <line x1="12" y1="3" x2="12" y2="15"></line>
        </svg>
      </div>
      <p className="upload-text">Drop files or Take a picture</p>
      <p className="file-formats">Supported formats: png, jpeg, jpg</p>
      <input
        type="file"
        onChange={handleFileChange}
        accept="image/png, image/jpeg, image/jpg"
        style={{ display: "none" }}
        id="file-input"
      />
      <div className="button-group">
        <label htmlFor="file-input" className="select-file-btn">
          SELECT FILE
        </label>
        <button onClick={startCamera} className="select-file-btn">
          TAKE A PICTURE
        </button>
      </div>
    </div>
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          ></div>

          <div className="split-item">
            <div
              className="split-item-content center-content-mobile reveal-from-left"
              data-reveal-container=".split-item"
            >
              <div className="vision-api-header">
                <h1 className="mt-0 mb-12 personalized-shopping">
                  Vision API
                  <a
                    href="https://docs.ezout.store/docs/vision-detect"
                    className="api-doc-button"
                  >
                    <span>API Doc</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="7" y1="17" x2="17" y2="7"></line>
                      <polyline points="7 7 17 7 17 17"></polyline>
                    </svg>
                  </a>
                </h1>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("../../assets/images/youtube.jpg")}
                  alt="Features split 01"
                  width={528}
                  height={400}
                  className="rounded-image"
                />
              </div>
              <p className="m-0 reduces-cognitive">
                Our advanced computer vision platform specializes in fresh
                produce detection and analysis. Leveraging cutting-edge machine
                learning algorithms, our API offers rapid and precise
                identification of fruits and vegetables from images.
              </p>
              <div className="small-break" />
              <ul className="m-0 reduces-cognitive">
                <li>
                  <strong>Step One: Upload an image</strong>
                  <br />
                  Prepare your image file for analysis
                </li>
                <li>
                  <strong>Step Two: Immediate processing</strong>
                  <br />
                  Vision API analyzes the uploaded image
                </li>
                <li>
                  <strong>Step Three: Receive detailed results</strong>
                  <br />
                  Get analysis and object recognition
                </li>
              </ul>
            </div>
          </div>

          <div className={splitClasses}>
            <div className="split-item try-api-section">
              <div className="file-upload-container">
                {!imagePreview && !showCamera && <FileUploadArea />}
                {showCamera && (
                  <div className="camera-container">
                    <video ref={videoRef} autoPlay className="camera-video" />
                    <canvas ref={canvasRef} className="hidden-canvas" />
                    <button onClick={capturePhoto} className="confirm-btn">
                      Capture
                    </button>
                  </div>
                )}
                {imagePreview && (
                  <div>
                    <img
                      src={imagePreview}
                      alt="Preview"
                      className="image-preview"
                    />
                    {!loading && !results && (
                      <button onClick={handleUpload} className="confirm-btn">
                        Next
                      </button>
                    )}
                  </div>
                )}
                {loading && <div className="loading">Loading...</div>}
                {results && (
                  <div className="results">
                    <h3>Detected Result</h3>
                    {results && (
                      <>
                        Detected product:{" "}
                        {results.map((label, index) => (
                          <span key={index}>
                            {label}
                            {index < results.length - 1 && ", "}
                          </span>
                        ))}
                      </>
                    )}
                    <button
                      onClick={() => {
                        setFile(null);
                        setImagePreview(null);
                        setResults(null);
                      }}
                      className="reset-btn"
                    >
                      Reset
                    </button>
                    <button
                      onClick={() => {
                        setFile(null);
                        setImagePreview(null);
                        setResults(null);
                      }}
                      className="new-file-btn"
                    >
                      New File
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="split-item ">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs interactive-shopping fw-600 tt-u mb-8">
                  INTERACTIVE SHOPPING
                </div>
                <h3 className="mt-0 mb-12 personalized-shopping">
                  Personalized Shopping Experience
                </h3>
                <h6 className="mt-0 mb-12 ai-assistant">AI assistant</h6>
                <ul>
                  <li className="m-0 reduces-cognitive ">
                    Reduces cognitive load on shoppers
                  </li>
                  <li className="m-0 reduces-cognitive">
                    Increases customer retention / brand loyalty
                  </li>
                  <li className="m-0 reduces-cognitive">
                    Saves customer time by allowing payment through cart system
                  </li>
                  <li className="m-0 reduces-cognitive">
                    Increases purchase amount by 30% on average through
                    personalized recommendations and item promotion
                  </li>
                </ul>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("../../assets/images/personlized.png")}
                  alt="Features split 01"
                  width={528}
                  height={400}
                  className="rounded-image"
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs interactive-shopping fw-600 tt-u mb-8">
                  NO MORE THEFTS
                </div>
                <h3 className="mt-0 mb-12 personalized-shopping">
                  Reduced Shrinkage
                </h3>
                <h6 className="mt-0 mb-12 ai-assistant">
                  AI powered anti-theft systems
                </h6>
                <ul>
                  <li className="m-0 reduces-cognitive ">
                    Reduce shrinkage by more than 50%
                  </li>
                  <li className="m-0 reduces-cognitive">
                    Lowers the cost of anti-theft measures by stores
                  </li>
                  <li className="m-0 reduces-cognitive">
                    Real-time database updates of store inventory allow for
                    notifications of potential theft
                  </li>
                </ul>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("../../assets/images/map-demo.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
