import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import { FaArrowCircleRight } from "react-icons/fa";
import Modal from '../elements/Modal';
import './Hero.css';

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Hero = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const outerClasses = classNames(
        'hero section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        ' section-inner hero-inner ',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const [videoModalActive, setVideomodalactive] = useState(false);

    const openModal = (e) => {
        e.preventDefault();
        setVideomodalactive(true);
    }

    const closeModal = (e) => {
        e.preventDefault();
        setVideomodalactive(false);
    }

    return (
        <section {...props} className={outerClasses}>
            <div className="container">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <h1 className="hero-title">
                            Elevate your<br/>
                            grocery store<br/>
                            experience
                        </h1>
                        <div className="hero-cta">
                            <Button tag="a" className="hero-button" wideMobile onClick={openModal}>
                                Explore Now <FaArrowCircleRight className="button-icon"/>
                            </Button>
                        </div>
                        <Modal
                            id="video-modal"
                            show={videoModalActive}
                            handleClose={closeModal}
                            video="https://bit.ly/3VZ6R0g"
                            videoTag="iframe"
                        />
                    </div>
                    <div className="hero-figure">
                        <Image
                            className="hero-image"
                            src={require('../../assets/images/payment-demo.jpeg')}
                            alt="Features split 01"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;